.corporate_bg {
  background: #ffffff 0% 0% no-repeat padding-box;
}

.corporate_no_content {
  top: 324px;
  left: 475px;
  height: 125px;
  font: normal normal normal 25px/38px Poppins;
  letter-spacing: 0px;
  color: #939198;
  text-transform: capitalize;
}
