.mock_result_h6 {
  font: normal normal medium 29px/43px Poppins;
  letter-spacing: 0px;
  color: #463196;
}
.mock_result_card {
  height: 239px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 13px #00000012;
  border-radius: 5px;
}

.mock_result_p {
  top: 311px;
  left: 683px;
  text-align: left;
  height: 25px;

  font: normal normal medium 18px/27px Poppins;
  letter-spacing: 0px;
  color: #939198;
  opacity: 1;
}
.correct {
  font: normal normal medium 16px/25px Poppins;
  letter-spacing: 0px;
  color: #1ba643;
  text-decoration: none;
}
.correct2 {
  font: normal normal medium 16px/25px Poppins;
  letter-spacing: 0px;
  color: #f7684a;
  text-decoration: none;
}
.correct3 {
  font: normal normal medium 16px/25px Poppins;
  letter-spacing: 0px;
  color: #c8c1df;
  text-decoration: none;
}
