.otp_card {
  width: 350px;
  padding: 10px;
  border-radius: 20px;
  background: #fff;
  border: none;
  height: 350px;
  position: relative;
}

.mobile-text {
  color: #989696b8;
  font-size: 15px;
}

.form-control {
  margin-right: 12px;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #ff8880;
  outline: 0;
  box-shadow: none;
}

.cursor {
  cursor: pointer;
}
.otp_p {
  font: normal normal normal 18px/27px Poppins;
  letter-spacing: 0px;
  color: #1ba643;
}
.otp_h6 {
  font: normal normal 600 18px/27px Poppins;
  letter-spacing: 0px;
  color: #463196;
}
