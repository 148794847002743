.logoSearch {
    display: flex;
    gap: 0.75rem;
}

.search {
    display: flex;
    justify-content: space-between;
    background-color: white;
    border-radius: 10px;
    padding: 5px 18px;
    width: 100%;
}

.search>input {
    border: none;
    outline: none;
    background-color: transparent;
}

.s-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 5px;

    /* background: linear-gradient(98.63deg, #8f00ff 0%, #ff18b7 100%); */
}

.s-icon:hover {
    cursor: pointer;
}

.left_card_courses {
    background-color: white;

    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
        rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px;
}

.left_card_header {
    display: flex;
    justify-content: space-between;
}

.left_card_text {
    text-decoration: none;
    color: #9184c0;
    font-weight: 600;
}


.right_header {
    color: #5845a0;
}

.stream {
    background-color: #eeeeee;

    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
        rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px;
}

.course_h2 {
    font: normal normal 600 16px/25px Poppins;
    letter-spacing: 0px;
    color: #0f0c1a;
    text-transform: capitalize;
}

.button {
    width: 160px;
    border-radius: 10px 10px;
    background: white;
    border: none;
    margin-right: 20px;
}

.button2 {
    width: 80px;
    border-radius: 10px 10px;
    background: white;
    border: none;
    margin-right: 20px;
}

.text {
    text-decoration: none;
    color: #7566ae;
    font-weight: 700;
}

.text2 {
    color: black;
}

.text2>i {
    width: 10px;
    height: 10px;
}

.button_update {
    width: 120px;
    background-color: #eeeeee;
    border: 1px solid;
}

.button_update:hover {
    background-color: #eeeeee;
    border: 1px solid #9a8fc2;
    color: black;
}

.button_apply {
    width: 160px;
    background-color: #c8c1df;
    padding: 7px;
    border-radius: 7px;
    border: none;
}

.button_apply:hover {
    border: 1px solid #9489bc;
    background-color: #c8c1df;
}

.sharp_arrow {
    width: 10px;
    height: 10px;
}

.button_last {
    margin-left: 200px;
}

.courses_btn {
    border: 1px solid red;
    text-decoration: none;
}

.popular {
    top: 815px;
    left: 491px;

    background: #eeeeee 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 13px #0000000f;
    border-radius: 5px;
    opacity: 1;
}

.star {
    top: 849px;
    left: 950px;
    width: 11px;
    height: 11px;
    transform: matrix(-0.71, -0.71, 0.71, -0.71, 0, 0);
    background: #1ba643 0% 0% no-repeat padding-box;
    opacity: 1;
}

.star2 {
    top: 849px;
    left: 950px;
    width: 11px;
    height: 11px;
    transform: matrix(-0.71, -0.71, 0.71, -0.71, 0, 0);
    background: #f7684a 0% 0% no-repeat padding-box;
    opacity: 1;
}

.overview {
    top: 1125px;
    left: 585px;
    width: 3px;
    height: 6px;
    background: #463196 0% 0% no-repeat padding-box;
    opacity: 1;
}

.course_sm_name {
    text-align: start;
}

.course_average_part {
    width: 75%;
}

.curve_btn {

    width: -moz-available;
    height: 17px;
    padding: 10px;
    margin-top: -30px;
    border-bottom-right-radius: 200px;
    background: #EFEFEF;
    border: 0;
}


.curve_btn2 {

    width: -moz-available;

    height: 17px;
    border-top-right-radius: 190px;
    background: #EFEFEF;
    border: 0;


}





@media only screen and (max-width: 600px) {
    .courses_card_btn {
        display: flex;
        flex-wrap: wrap;
    }

    .course_card {
        padding: 20px;
    }

    .course_sm_name {
        text-align: center;
    }

    .course_average_part {
        width: 100%;
    }

    .btn-group {
        margin-top: 10px;
    }
}