.left_card {
  top: 588px;
  left: 128px;
  width: 100%;
  height: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0pt 6pt 2pt #4631961c;
  border: 1pt solid #bebebe;
  border-radius: 5pt;
}
.heading_sub {
  top: 535pt;
  left: 181pt;

  height: 23pt;
  text-align: left;
  font: normal normal 600 16pt/25pt Poppins;
  letter-spacing: 0pt;
  color: #939198;
}
.heading {
  top: 500pt;
  left: 181pt;

  height: 27pt;
  /* UI Properties */
  text-align: left;
  font: normal normal bold 20pt/27pt Recoleta;
  letter-spacing: 0pt;
  color: #463196;
  opacity: 1;
}
.rating_text {
  top: 505pt;
  left: 1027pt;

  height: 23pt;
  text-align: left;
  font: normal normal 600 16pt/25pt Poppins;
  letter-spacing: 0pt;
  color: #0f0c1a;
  opacity: 1;
}
.profile_img {
  top: 1070pt;
  left: 203pt;
  width: 38pt;
  height: 38pt;
  /* UI Properties */

  opacity: 1;
}
.profile_name {
  left: 252pt;

  text-align: left;
  font: normal normal 600 16pt/25pt Poppins;
  letter-spacing: 0pt;
  color: #0f0c1a;
  opacity: 0.9;
  padding: 0;
}
.enrolled_main {
  margin-top: -20px;
}
.enrolled {
  width: 90pt;
  height: 20pt;
  text-align: left;
  font: normal normal medium 14pt/21pt Poppins;
  letter-spacing: 0pt;
  color: #939198;
  opacity: 0.9;
}
.enrolled2 {
  width: 100%;

  height: 20pt;
  text-align: left;
  font: normal normal medium 14pt/21pt Poppins;
  letter-spacing: 0pt;
  color: #939198;
  opacity: 0.9;
  text-decoration: none;
}
.enrolled_img {
}
.star {
  top: 844pt;
  left: 1017pt;
  width: 14pt;
  height: 13pt;
  background: #f8b54c 0% 0% no-repeat padding-box;
  opacity: 1;
}
.star_img {
  top: 1072pt;
  left: 1017pt;
  width: 14pt;
  height: 13pt;
  /* UI Properties */
  background: transparent
    url("../../../img/colleges-inner-page/Icon\ awesome-star.png") 0% 0%
    no-repeat padding-box;

  opacity: 1;
}
.rating_text2 {
  left: 1037pt;
  width: 43pt;
  height: 23pt;
  text-align: left;
  font: normal normal 600 16pt/25pt Poppins;
  letter-spacing: 0pt;
  color: #0f0c1a;
  margin-top: -9px;
}
.review_text_middle {
  top: 905pt;
  left: 203pt;
  width: 207pt;
  height: 23pt;
  text-align: left;
  font: normal normal 600 16pt/25pt Poppins;
  letter-spacing: 0pt;
  color: #463196;
  opacity: 1;
  margin-left: 15px;
}
.review_text_last {
  top: 936pt;
  left: 203pt;

  height: 71pt;
  text-align: left;
  font: normal normal normal 16pt/25pt Poppins;
  letter-spacing: 0pt;
  color: #0f0c1a;
  opacity: 1;
  margin-left: 15px;
}

.announcement_text {
  top: 760px;
  left: 460px;
  width: 725px;
  height: 49px;
  background: rgba(238, 238, 238, 0.31) 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
}
