.logoSearch {
  display: flex;
  gap: 0.75rem;
}
.form-check > label > a {
  text-decoration: none;
  color: black;
}

.search {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: 10px;
  padding: 5px 18px;
  width: 100%;
}

.search > input {
  border: none;
  outline: none;
  background-color: transparent;
}

.s-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 5px;

  /* background: linear-gradient(98.63deg, #8f00ff 0%, #ff18b7 100%); */
}

.s-icon:hover {
  cursor: pointer;
}
.logoSearch2 {
}

.college_search2 > input {
  top: 294px;
  left: 109px;
  width: 235px;
  height: 34px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #70707045;
  border-radius: 6px;
}

.left_card_college {
  background-color: white;

  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px;
}
.left_card_college_header {
  display: flex;
  justify-content: space-between;
}
.left_card_college_text {
  text-decoration: none;
  color: #9184c0;
  font-weight: 600;
}
