.side ul li {
  text-align: start;
}

.college_inner_tab {
  display: none;
}

@media only screen and (max-width: 600px) {
  .college_inner_tab {
    display: inline;
  }
}