.card_header {
  background: #463196;
  width: "100%";
  height: 47px;
  border-radius: 10px 10px 0px 0px;
}
.card_text {
  color: white;
  text-align: center;

  text-decoration: none;
}
.menu_text {
  top: 229px;

  text-align: left;
  font: normal normal medium 14px/21px Poppins;
  letter-spacing: 0px;
  color: #0f0c1a;
  text-transform: capitalize;
  opacity: 0.9;
  margin-left: 10px;
  padding: 20px;
}
.card_header {
  background: #463196;
  width: "100%";
  height: 47px;
  border-radius: 10px 10px 0px 0px;
}
.card_text {
  color: white;
  text-align: center;

  text-decoration: none;
}
.menu_text {
  top: 229px;

  text-align: left;
  font: normal normal medium 14px/21px Poppins;
  letter-spacing: 0px;
  color: #0f0c1a;
  text-transform: capitalize;
  opacity: 0.9;
  margin-left: 10px;
  padding: 20px;
  text-decoration: none;
}

.accordion input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.accordion-wrapper {
  border-radius: 8px;
  overflow: hidden;

  width: 100%;
  margin: 0 auto;
}
.accordion {
  width: 100%;
  color: rgb(0, 0, 0);
  overflow: hidden;
  margin-bottom: 16px;
}
.accordion:last-child {
  margin-bottom: 0;
}
.accordion-label {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 16px;

  font-weight: bold;
  cursor: pointer;
  font-size: 20px;
}
.accordion-label:hover {
}
.accordion-label::after {
  content: "\276F";
  width: 16px;
  height: 16px;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.accordion-content {
  max-height: 0;
  padding: 0 16px;
  color: rgba(4, 57, 94, 1);
  background: white;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.accordion-content p {
  margin: 0;
  color: rgba(4, 57, 94, 0.7);
  font-size: 18px;
}
input:checked + .accordion-label {
  /* background: rgba(4, 57, 94, 1); */
}
input:checked + .accordion-label::after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
input:checked ~ .accordion-content {
  max-height: 100vh;
  padding: 16px;
}

.left_circle_img {
  width: 14px;
  height: 14px;
  margin-top: 21px;
}

.label_size {
  top: 853px;
  left: 156px;
  width: 41px;
  height: 17px;
  text-align: left;
  font: normal normal medium 12px/18px Poppins;
  font-size: 14px;
  letter-spacing: 0px;
  color: #0f0c1a;
  text-transform: uppercase;
}
.last_part {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 13px #0000000f;
  border-radius: 5px;
}
.second_li {
  margin-left: -40px;
}
