.profile_header {
  text-align: center;
  font: normal normal bold 30px/41px Recoleta;
  letter-spacing: 0px;
  color: #463196;
  margin-top: 30px;
}
.profile_label {
  font: normal normal 600 18px/27px Poppins;
  letter-spacing: 0px;
  color: #463196;
  float: left;
}
.profile_label2 {
  font: normal normal 600 18px/27px Poppins;
  letter-spacing: 0px;
  color: hsl(252, 51%, 39%);
  float: left;
}
.profile_label3 {
  font: normal normal 600 18px/27px Poppins;
  letter-spacing: 0px;
  color: hsl(252, 51%, 39%);
  float: left;
}
.form > input {
  height: 60px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c8c1df;
  border-radius: 5px;
}
.form2 > input {
  height: 120px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c8c1df;
  border-radius: 5px;
}
.profile_btn {
  width: 234px;
  height: 60px;
  background: #463196 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  color: white;
}
.profile_btn2 {
  width: 234px;
  height: 60px;
  background: #c8c1df 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  color: #463196;
}
.profile-head {
  float: left;
  margin-top: 70px;
}
.cover {
  background-image: url(https://img.freepik.com/premium-photo/universal-linkedin-banner-with-portrait-beautiful-black-girl_198208-833.jpg?w=2000);
  background-size: cover;
  background-repeat: no-repeat;
  height: 220px;
}
