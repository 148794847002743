.review_main {
  margin: 0px 80px
}

.profile_header2 {
  text-align: left;
  font: normal normal bold 30px/41px Recoleta;
  letter-spacing: 0px;
  color: #463196;
  margin-top: 30px;
  text-decoration: underline;
}

.question {
  margin-top: -57px;
  margin-left: 40px
}

.form>select {
  height: 60px;

  border: 1px solid #c8c1df;
  border-radius: 5px;
}

@media only screen and (max-width: 600px) {
  .review_main {
    margin: 0px
  }

  .question {
    margin-top: -60px;
    float: right;
  }
}