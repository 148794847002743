.right_header {
    color: #5845a0;
}

.stream {
    background-color: #eeeeee;

    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
        rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px;
}

.button {
    width: 160px;
    border-radius: 10px 10px;
    background: white;
    border: none;
    margin-right: 20px;
}

.button2 {
    width: 80px;
    border-radius: 10px 10px;
    background: white;
    border: none;
    margin-right: 20px;
}

.text {
    text-decoration: none;
    color: #7566ae;
    font-weight: 700;
}

.text2 {
    color: black;
}

.text2>i {
    width: 10px;
    height: 10px;
}

.button_update {
    width: 120px;
    background-color: #eeeeee;
    border: 1px solid;
}

.button_update:hover {
    background-color: #eeeeee;
    border: 1px solid #9a8fc2;
    color: black;
}

.button_apply {
    width: 160px;
    background-color: #c8c1df;
    padding: 7px;
    border-radius: 7px;
    border: none;
}

.button_apply:hover {
    border: 1px solid #9489bc;
    background-color: #c8c1df;
}

.sharp_arrow {
    width: 10px;
    height: 10px;
}

.button_last {
    margin-left: 200px;
}

.exam_sm {
    display: none;
}


.logoSearch {
    display: flex;
    gap: 0.75rem;
}

.search {
    display: flex;
    justify-content: space-between;
    background-color: white;
    border-radius: 10px;
    padding: 5px 18px;
    width: 100%;
}

.search>input {
    border: none;
    outline: none;
    background-color: transparent;
}

.s-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 5px;

    /* background: linear-gradient(98.63deg, #8f00ff 0%, #ff18b7 100%); */
}

.s-icon:hover {
    cursor: pointer;
}

.left_card_exam {
    background-color: white;

    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
        rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px;
}

.left_card_exam_header {
    display: flex;
    justify-content: space-between;
}

.left_card_exam_text {
    text-decoration: none;
    color: #9184c0;
    font-weight: 600;
}

.application_date {
    width: 40%;
}

.application_date2 {
    width: 30%;
}

.result_date {
    width: 40%;
}

.result_date2 {
    width: 30%;
}



@media only screen and (max-width: 600px) {
    .button_last {
        display: flex;
        justify-content: space-around;
        width: 100%;
        margin-left: 0%;
        margin-bottom: 10px;
    }


    .exam_sm {
        display: inline;
    }

    .exam_text {
        text-align: center;
        margin-top: 10px;
        width: 100%;
    }

    .result_date {
        font-size: 12px;
        margin-left: 20px;
    }

    .result_date2 {
        font-size: 12px;
        margin-left: 10px;
    }

    .application_date h6 {
        font-size: 12px;
        margin-top: 3px;
    }

    .application_date2 h6 {
        font-size: 12px;
        margin-top: 3px;
    }
}




@media (min-width: 768px) and (max-width: 1199.98px) {
    .button_last {
        display: flex;
        justify-content: flex-end;
        width: 80%;
        margin-right: 20%;
        margin-bottom: 10px;
    }


}