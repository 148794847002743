.main_head {
  width: 100%;
  height: 277px;
  background: url("https://i.ibb.co/s2nxWp3/sangga-rima-roman-selia-bg-Qg-AKag-QB4-unsplash-2x.png");
  background-size: cover;
  position: relative;
  overflow: hidden;
  padding: 30px 30px;
  margin: 0%;
}

.header_img {
  margin-top: -183px;
  width: 100%;
  height: 530px;

  background: transparent linear-gradient(180deg, #4631961f 0%, #141024 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 13px #4631961c;
  border-radius: 5px 15px 0px 0px;
  opacity: 1;
}

.header_text {
  position: absolute;
  top: 144px;
  left: 250px;

  text-align: left;

  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.img_main4 {
  margin-top: -460px;
}

.header_icon {
  margin-top: -15px;
  margin-right: 5px;
}

.announcement {
  padding-left: 27px;
}

.announcement_text1_bg {
  top: 340px;
  left: 91px;
  width: 239px;
  height: 61px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border: 1px solid #c8c1df;
  border-radius: 5px;
  opacity: 1;
}

.announcement_text1 {
  top: 358px;
  left: 139px;
  width: 143px;
  height: 25px;
  text-align: left;
  font: normal normal 600 18px/27px Poppins;
  letter-spacing: 0px;
  color: #463196;
  opacity: 1;
}

.announcement_text2_bg {
  top: 359px;
  left: 350px;
  width: 100%;
  height: 23px;
  text-align: left;
  font: normal normal 600 16px/25px Poppins;
  letter-spacing: 0px;
  text-transform: capitalize;
  opacity: 0.9;
}

.announcement_text2 {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 23px;
  text-align: left;
  font: normal normal 600 16px/25px Poppins;
  letter-spacing: 0px;
  color: #f7684a;
  text-transform: capitalize;
}

.side_menu {
  color: #f7684a;
}

.circle_inner {
  width: 132px;
  height: 132px;
  border-radius: 50%;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border: 4px solid #ffffff;
  margin-top: -36px;
}

.circle_inner_text {
  margin-left: 25px;
  width: 70px;
  margin-top: -115px;
  text-align: center;
  font: normal normal 600 18px/27px Poppins;
  letter-spacing: 0px;
  color: #463196;
}

@media only screen and (max-width: 600px) {
  .details_sm_details {
    margin-top: 120px;
  }
}