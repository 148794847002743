.left_card {
  background-color: white;

  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px;
  margin-top: -30px;
  width: 90%;
  height: 600px;
}

.tab_bg {
  border-bottom: 1px;
  border-color: "divider";
  background: "#463196 0 0 no-repeat padding-box";
  padding-top: 1px;
  margin: 0px 8px;
}

.left_card_header {
  display: flex;
  justify-content: space-between;
}

.left_card_text {
  text-decoration: none;
  color: #9184c0;
  font-weight: 600;
}


/*-----*/
@keyframes content {
  from {
    opacity: 0;
    transform: translateY(5%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

@media (max-width: 1000px) {
  .about_tabs ul li label {
    white-space: initial;
  }

  .about_tabs ul li label br {
    display: initial;
  }

  .about_tabs ul li label svg {
    height: 1.5em;
  }
}

@media (max-width: 600px) {
  .about_tabs ul li label {
    padding: 5px;
    border-radius: 5px;
  }

  .about_tabs ul li label span {
    display: none;
  }

  .tab_bg {
    border-bottom: 1px;
    border-color: "divider";
    background: "red";
    padding-top: 1px;
    margin: 0px 8px;
  }

  .about_tabs .slider {
    display: none;
  }

  .about_tabs .content {
    margin-top: 20px;
  }

  .about_tabs .content section h2 {
    display: block;
  }

  .content_text {
    top: 541px;
    left: 447px;
    width: 100%;

    /* UI Properties */
    text-align: left;
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0px;
    color: #0f0c1a;
    opacity: 1;
  }
}

.side_menu {
  color: #000000;
}

.content_title {
  left: 447px;

  text-align: left;
  font: normal normal bold 20px/27px Recoleta;
  letter-spacing: 0px;
  color: #463196;
  opacity: 1;
}



.menu_li {
  top: 422px;
  left: 602px;
  width: 61px;
  height: 21px;
  text-align: left;
  font: normal normal medium 15px/23px Poppins;
  font-weight: 300;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}