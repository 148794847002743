.right_header {
    color: #5845a0;
}

.font_family_h1 {
    font-family: 'Recoleta', sans-serif;
    font-weight: bolder
}

.stream {
    background-color: #eeeeee;

    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
        rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px;
}

.stream2 {
    width: 180px;
}

.streamText {
    display: block;
    height: 10px;
    margin-right: 20px;
}

.button {
    width: 160px;
    border-radius: 10px 10px;
    background: white;
    border: none;
    margin-right: 59px;
}

.button2 {
    /* width: 80px;
    border-radius: 10px 10px;
  
    border: none;
    margin-right: 20px; */
    top: 321px;
    left: 621px;
    width: 51px;
    height: 25px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
}

.text {
    text-decoration: none;
    color: #7566ae;
    font-weight: 700;
}

.text2 {
    color: black;
}

.text2>i {
    width: 10px;
    height: 10px;
}

.button_update {
    width: 120px;
    background-color: #eeeeee;
    border: 1px solid;
}

.button_update:hover {
    background-color: #eeeeee;
    border: 1px solid #9a8fc2;
    color: black;
}

.button_apply {
    width: 160px;
    background-color: #c8c1df;
    padding: 7px;
    border-radius: 7px;
    border: none;
}

.button_apply:hover {
    border: 1px solid #9489bc;
    background-color: #c8c1df;
}

.sharp_arrow {
    width: 10px;
    height: 10px;
}

.button_last {
    margin-left: 200px;
}

.card_footer {
    top: 780px;
    left: 443px;

    /* UI Properties */
    background: #c8c1df 0% 0% no-repeat padding-box;
    opacity: 1;
}

.card_footer a {
    text-decoration: none;
    top: 793px;
    left: 611px;
    width: 59px;
    height: 16px;
    /* UI Properties */
    text-align: left;
    font: normal normal medium 11px/17px Poppins;
    letter-spacing: 0px;
    color: #0f0c1a;
    opacity: 1;
}

.card_list_img {
    width: 27px;
}

.logoSearch {
    display: flex;
    gap: 0.75rem;
}

.search {
    display: flex;
    justify-content: space-between;
    background-color: white;
    border-radius: 10px;
    padding: 5px 18px;
    width: 100%;
}

.search>input {
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
}

.s-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 5px;

    /* background: linear-gradient(98.63deg, #8f00ff 0%, #ff18b7 100%); */
}

.s-icon:hover {
    cursor: pointer;
}

.logoSearch2 {}

.college_search2>input {
    top: 294px;
    left: 109px;
    width: 235px;
    height: 34px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #70707045;
    border-radius: 6px;
}

.left_card_college {
    background-color: white;

    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
        rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px;
}

.left_card_college_header {
    display: flex;
    justify-content: space-between;
}

.left_card_college_text {
    text-decoration: none;
    color: #9184c0;
    font-weight: 600;
}

@media only screen and (max-width: 600px) {
    .button2 {
        margin-top: 10px;
    }

    .button {
        margin-top: 10px;
    }
}

@media (min-width: 992px) {
    .college_sort {
        width: 75%;
    }
}