.main {
  background: #ffffff;
  box-shadow: 0px 6px 13px #0000000f;
}

.search {
  top: 162px;
  left: 410px;
  width: 100%;
  height: 50px;
  box-shadow: 0px 6px 13px #0000000f;
  border: 1px solid #bebebe;
  border-radius: 5px;
}

.box_part {
  height: 141px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 13px #4631961c;
  border-radius: 5px;
  margin-left: 10px;
}

.img_text {
  text-align: left;
  font: normal normal 600 12px/18px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  text-shadow: 0px 5px 10px #00000029;
  margin-top: -130px;
  font-size: xx-small;
}

.img_text_bg {
  width: 23px;
  height: 23px;
  background: #797171 0% 0% no-repeat padding-box;
  border: 1px solid #707070a3;
  opacity: 1;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  border-radius: 50%;
  padding: 2px;
}

.icon {
  top: 284px;
  left: 464px;
  padding: 10px;
  background: #463196 0% 0% no-repeat padding-box;
  color: white;
}



.main2 {
  background: #ffffff;
  box-shadow: 0px 6px 13px #0000000f;
  height: 100vh;
}

.post_btn {
  height: 38px;
  background: #463196 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 13px #0000000f;
  border-radius: 5px;
  color: white;
  width: 120px;
  border: none;
  margin: 15px 0;
}

.search_main {
  width: 100%;
  height: 300px;
  box-shadow: 0px 6px 13px #0000000f;
  border: 1px solid #bebebe;
  border-radius: 5px;
}

.logoSearch3 {
  display: flex;
  gap: 0.75rem;
}

.search3 {
  display: flex;
  justify-content: space-between;
  background-color: white;

  padding: 5px 18px;
  width: 100%;
}

.search3>input {
  border: none;
  outline: none;
  margin-top: 10px;
  color: red;
}

.s-icon3 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 5px;

  /* background: linear-gradient(98.63deg, #8f00ff 0%, #ff18b7 100%); */
}

.s-icon3:hover {
  cursor: pointer;
}

.logoSearch3 {}

.second_input>input {
  width: 695px;
  height: 37px;
  border: 0.5px solid #463196;
  border-radius: 5px;
  margin-left: -70px;
  margin-top: 20px;
}

.second_input2>textarea {
  margin-left: -70px;
  height: 81px;
  border: 0.5px solid #463196;
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px;
}

.suggested_card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 13px #0000000f;
  border-radius: 5px;
  height: 150px;
  margin-left: 10px;
}

.suggested_text {
  top: 506px;
  left: 633px;

  height: 20px;
  text-align: center;
  font: normal normal 600 14px/21px Poppins;
  letter-spacing: 0px;
  color: #463196;
}

.suggested_span {
  text-decoration: none;

  left: 523px;
  height: 17px;
  text-align: left;
  font: normal normal medium 12px/18px Poppins;
  letter-spacing: 0px;
  color: #0f0c1a;
}

.suggested_img {
  width: 31px;
  height: 31px;
}

.suggested_p {
  top: 576px;
  left: 523px;
  width: 244px;
  height: 31px;
  text-align: left;
  font: normal normal medium 10px/16px Poppins;
  letter-spacing: 0px;
  color: #7a7880;
}

.suggested_btn1 {
  width: 76px;
  height: 31px;
  background: #c8c1df 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 13px #0000000f;
  border-radius: 5px;
  border: none;
}

.suggested_btn2 {
  width: 76px;
  margin-left: 10px;
  height: 31px;
  text-align: center;
  font: normal normal medium 9px/13px Poppins;
  letter-spacing: 0px;
  color: #0f0c1a;
  border: none;
}



.home_card_img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
}

.home_card_img2 {
  width: 26px;
  height: 26px;
  border-radius: 50%;
}

.home_card_name {
  text-decoration: none;
  top: 728px;
  left: 495px;
  width: 136px;
  height: 19px;
  text-align: left;
  font: normal normal 600 13px/20px Poppins;
  letter-spacing: 0px;
  color: #0f0c1a;
}

.home_card_text {
  text-decoration: none;
  top: 752px;
  left: 513px;
  width: 204px;
  height: 15px;
  text-align: left;
  font: normal normal medium 10px/16px Poppins;
  letter-spacing: 0px;
  color: #7a7880;
  font-size: 14px;
}

.second_text {
  text-align: start;
  margin-left: 100px;
  margin-top: -10px;
}




.home_card_time {
  text-decoration: none;
}

.img_hr {
  margin: 20px 40px 0 40px;
}

.hr {
  margin: 40px 30px 0 30px;
}

.hr1 {
  margin: 0 30px 0 30px;
}

.view {
  background: #ffffff 0% 0% no-repeat padding-box;
}