.circle {
  width: 8px;
  height: 8px;
  background: #463196;
  margin-top: -215px;
  margin-left: -90px;
}

.circle2 {
  width: 8px;
  height: 8px;
  background: #463196;
  margin-left: 20px;
}

.right_side_text {

  text-align: left;
  font: normal normal medium 12px/18px Poppins;
  font-size: 14px;
  letter-spacing: 0px;
  color: #0f0c1a;
  text-decoration: none;
}

.small_text_main {
  margin-left: 20px;
  margin-top: 7px;
}

.small_text {
  text-align: left;
  font: normal normal medium 10px/16px Poppins;
  letter-spacing: 0px;
  color: #7a7880;
}

.link_text {
  top: 437px;
  left: 1108px;
  width: 61px;
  text-align: left;
  text-decoration: underline;
  font: normal normal medium 10px/16px Poppins;
  letter-spacing: 0px;
  color: #1ba643;
}

.message_part {
  margin-left: 70px;
}

.btn1 {
  top: 868px;
  left: 1024px;
  width: 118px;
  height: 37px;
  background: #c8c1df 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 13px #0000000f;
  border-radius: 5px 0px 0px 5px;
  border: 0;
}

.btn2 {
  top: 868px;
  left: 1143px;
  width: 118px;
  height: 37px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 13px #0000000f;
  border-radius: 0px 5px 5px 0px;
  border: 0;
}


.side_modal {
  background: #463196 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 10px #00000021;
  border-radius: 5px 5px 0px 0px;
  color: white;
}

.side_modal_body {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 13px #46319629;
}

.message_right {
  width: 204px;
  height: 144px;
  text-align: start;
  padding: 20px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 20px 20px 0px 20px;
}

.message_left {
  float: left;
  width: 204px;
  height: 144px;
  text-align: start;
  padding: 20px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 20px 20px 0px 20px;
}

.side_message_input>input {
  background: none;
  border: none;
}

@media only screen and (max-width: 600px) {
  .btn_input {
    top: 926px;
    left: 1024px;
    width: auto;
    height: 32px;
    border: 0.5px solid #7a7880;
    border-radius: 5px;
  }
}

@media only screen and (min-width: 600px) {
  .btn_input {

    width: 60%;
    height: 32px;
    border: 0.5px solid #7a7880;
    border-radius: 5px;
  }
}