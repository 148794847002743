.img_main {
  margin-top: -60px;
}

.all_img {
  height: 200px;
}
.all_p_text1 {
  text-align: left;
  font: normal normal 600 14px/21px Poppins;
  letter-spacing: 0px;
  color: #0f0c1a;
  text-transform: capitalize;
  padding: 0%;
  margin: 0%;
}
.all_p_text2 {
  text-align: left;
  font: normal normal medium 10px/16px Poppins;
  letter-spacing: 0px;
  color: #7a7880;
  padding: 0%;
  margin: 0%;
}
.all_p_text3 {
  text-align: left;
  font: normal normal medium 10px/16px Poppins;
  letter-spacing: 0px;
  color: #1ba643;
}
.all_p_text4 {
  text-align: left;
  font: normal normal medium 10px/16px Poppins;
  letter-spacing: 0px;
  color: #0f0c1a;
}

.btn_all_1 {
  background: #c8c1df 0% 0% no-repeat padding-box;
  border: 1px solid #c8c1df;
  border-radius: 5px;

  padding: 10px;
  width: 120px;
}
.btn_all_2 {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c8c1df;
  border-radius: 5px;

  padding: 10px;
  width: 120px;
  margin: 0 10px;
}
.btn_all_3 {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c8c1df;
  border-radius: 5px;

  padding: 10px;
  width: 120px;
}
.circle3 {
  top: 222px;
  left: 121px;
  width: 82px;
  height: 82px;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 50%;
}
.text3 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 79px;
  font: normal normal 600 12px/17px Poppins;
  letter-spacing: 0px;
  color: #463196;
  opacity: 1;
  margin-top: -85px;
  margin-left: 7px;
}
