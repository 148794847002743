.left_card {
  background-color: white;

  box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px;
  margin-top: -30px;
  width: 90%;
  height: 600px;
}
.left_card_header {
  display: flex;
  justify-content: space-between;
}
.left_card_text {
  text-decoration: none;
  color: #9184c0;
  font-weight: 600;
}
.tabs {
  position: relative;
  background: white;
  padding-bottom: 80px;
  width: 100%;
  height: 250px;
  padding: 0px 27px;
  border-radius: 5px;
}
.tabs input[name="tab-control"] {
  display: none;
}
.tabs .content section h2,
.tabs ul li label {
  font-family: "Montserrat";
  font-weight: bold;
  font-size: 18px;
  color: #f8fbff;
}
.tabs ul {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 62px;
  background: #463196 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  color: white;
}
.tabs ul li {
  box-sizing: border-box;

  padding: 0 10px;
  text-align: start;
}
.tabs ul li label {
  transition: all 0.3s ease-in-out;

  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  -webkit-touch-callout: none;

  text-align: left;
  font: normal normal medium 15px/23px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
.tabs ul li label br {
  display: none;
}

.tabs ul li label:hover,
.tabs ul li label:focus,
.tabs ul li label:active {
  outline: 0;
  color: #000000;
}
.tabs ul li label:hover svg,
.tabs ul li label:focus svg,
.tabs ul li label:active svg {
  fill: #bec5cf;
}
.tabs .slider {
  position: relative;
  width: 12%;
  transition: all 0.33s cubic-bezier(0.38, 0.8, 0.32, 1.07);
  padding-left: 15px;
}
.tabs .slider .indicator {
  position: relative;
  width: 50px;
  max-width: 100%;
  margin-top: -20px;
  height: 4px;
  background: #ffffff;
  border-radius: 1px;
}
.tabs .content {
  margin-top: 30px;
}
.tabs .content section {
  display: none;
  animation-name: content;
  animation-direction: normal;
  animation-duration: 0.3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  line-height: 1.4;
}
.tabs .content section h2 {
  color: #ffffff;
  display: none;
}
.tabs .content section h2::after {
  content: "";
  position: relative;
  display: block;
  width: 30px;
  height: 3px;
  background: #ffffff;
  margin-top: 5px;
  left: 1px;
}
.tabs
  input[name="tab-control"]:nth-of-type(1):checked
  ~ ul
  > li:nth-child(1)
  > label {
  cursor: default;
  color: #ffffff;
}
.tabs
  input[name="tab-control"]:nth-of-type(1):checked
  ~ ul
  > li:nth-child(1)
  > label
  svg {
  fill: #ffffff;
}
@media (max-width: 600px) {
  .tabs
    input[name="tab-control"]:nth-of-type(1):checked
    ~ ul
    > li:nth-child(1)
    > label {
    background: rgba(247, 247, 247, 0.08);
  }
}
.tabs input[name="tab-control"]:nth-of-type(1):checked ~ .slider {
  transform: translateX(0%);
}
.tabs
  input[name="tab-control"]:nth-of-type(1):checked
  ~ .content
  > section:nth-child(1) {
  display: block;
}

.tabs
  input[name="tab-control"]:nth-of-type(2):checked
  ~ ul
  > li:nth-child(2)
  > label {
  cursor: default;
  color: #ffffff;
}
.tabs
  input[name="tab-control"]:nth-of-type(2):checked
  ~ ul
  > li:nth-child(2)
  > label
  svg {
  fill: #ffffff;
}
@media (max-width: 600px) {
  .tabs
    input[name="tab-control"]:nth-of-type(2):checked
    ~ ul
    > li:nth-child(2)
    > label {
    background: rgba(0, 0, 0, 0.08);
  }
}
.tabs input[name="tab-control"]:nth-of-type(2):checked ~ .slider {
  transform: translateX(80%);
}
.tabs
  input[name="tab-control"]:nth-of-type(2):checked
  ~ .content
  > section:nth-child(2) {
  display: block;
}

.tabs
  input[name="tab-control"]:nth-of-type(3):checked
  ~ ul
  > li:nth-child(3)
  > label {
  cursor: default;
  color: #ffffff;
}
.tabs
  input[name="tab-control"]:nth-of-type(3):checked
  ~ ul
  > li:nth-child(3)
  > label
  svg {
  fill: #ffffff;
}
@media (max-width: 600px) {
  .tabs
    input[name="tab-control"]:nth-of-type(3):checked
    ~ ul
    > li:nth-child(3)
    > label {
    background: rgba(0, 0, 0, 0.08);
  }
}
.tabs input[name="tab-control"]:nth-of-type(3):checked ~ .slider {
  transform: translateX(190%);
}
.tabs
  input[name="tab-control"]:nth-of-type(3):checked
  ~ .content
  > section:nth-child(3) {
  display: block;
}

.tabs
  input[name="tab-control"]:nth-of-type(4):checked
  ~ ul
  > li:nth-child(4)
  > label {
  cursor: default;
  color: #ffffff;
}
.tabs
  input[name="tab-control"]:nth-of-type(4):checked
  ~ ul
  > li:nth-child(4)
  > label
  svg {
  fill: #ffffff;
}
@media (max-width: 600px) {
  .tabs
    input[name="tab-control"]:nth-of-type(4):checked
    ~ ul
    > li:nth-child(4)
    > label {
    background: rgba(0, 0, 0, 0.08);
  }
}

.tabs input[name="tab-control"]:nth-of-type(4):checked ~ .slider {
  transform: translateX(300%);
}
.tabs
  input[name="tab-control"]:nth-of-type(4):checked
  ~ .content
  > section:nth-child(4) {
  display: block;
}

.tabs
  input[name="tab-control"]:nth-of-type(5):checked
  ~ ul
  > li:nth-child(5)
  > label {
  cursor: default;
  color: #ffffff;
}
.tabs
  input[name="tab-control"]:nth-of-type(5):checked
  ~ ul
  > li:nth-child(5)
  > label
  svg {
  fill: #ffffff;
}
@media (max-width: 600px) {
  .tabs
    input[name="tab-control"]:nth-of-type(5):checked
    ~ ul
    > li:nth-child(5)
    > label {
    background: rgba(0, 0, 0, 0.08);
  }
}

.tabs input[name="tab-control"]:nth-of-type(5):checked ~ .slider {
  transform: translateX(400%);
}
.tabs
  input[name="tab-control"]:nth-of-type(5):checked
  ~ .content
  > section:nth-child(5) {
  display: block;
}

/*----------*/

.tabs
  input[name="tab-control"]:nth-of-type(6):checked
  ~ ul
  > li:nth-child(6)
  > label {
  cursor: default;
  color: #ffffff;
}
.tabs
  input[name="tab-control"]:nth-of-type(6):checked
  ~ ul
  > li:nth-child(6)
  > label
  svg {
  fill: #ffffff;
}
@media (max-width: 600px) {
  .tabs
    input[name="tab-control"]:nth-of-type(6):checked
    ~ ul
    > li:nth-child(6)
    > label {
    background: rgba(0, 0, 0, 0.08);
  }
}

.tabs input[name="tab-control"]:nth-of-type(6):checked ~ .slider {
  transform: translateX(490%);
}
.tabs
  input[name="tab-control"]:nth-of-type(6):checked
  ~ .content
  > section:nth-child(6) {
  display: block;
}

/*----------*/

.tabs
  input[name="tab-control"]:nth-of-type(7):checked
  ~ ul
  > li:nth-child(6)
  > label {
  cursor: default;
  color: #ffffff;
}
.tabs
  input[name="tab-control"]:nth-of-type(7):checked
  ~ ul
  > li:nth-child(7)
  > label
  svg {
  fill: #ffffff;
}
@media (max-width: 600px) {
  .tabs
    input[name="tab-control"]:nth-of-type(7):checked
    ~ ul
    > li:nth-child(7)
    > label {
    background: rgba(0, 0, 0, 0.08);
  }
}

.tabs input[name="tab-control"]:nth-of-type(7):checked ~ .slider {
  transform: translateX(616%);
}
.tabs
  input[name="tab-control"]:nth-of-type(7):checked
  ~ .content
  > section:nth-child(7) {
  display: block;
}
/*-----*/

.tabs
  input[name="tab-control"]:nth-of-type(8):checked
  ~ ul
  > li:nth-child(8)
  > label {
  cursor: default;
  color: #ffffff;
}
.tabs
  input[name="tab-control"]:nth-of-type(8):checked
  ~ ul
  > li:nth-child(8)
  > label
  svg {
  fill: #ffffff;
}
@media (max-width: 600px) {
  .tabs
    input[name="tab-control"]:nth-of-type(8):checked
    ~ ul
    > li:nth-child(8)
    > label {
    background: rgba(0, 0, 0, 0.08);
  }
}

.tabs input[name="tab-control"]:nth-of-type(8):checked ~ .slider {
  transform: translateX(710%);
}
.tabs
  input[name="tab-control"]:nth-of-type(8):checked
  ~ .content
  > section:nth-child(8) {
  display: block;
}
/*-----*/

.tabs
  input[name="tab-control"]:nth-of-type(9):checked
  ~ ul
  > li:nth-child(9)
  > label {
  cursor: default;
  color: #ffffff;
}
.tabs
  input[name="tab-control"]:nth-of-type(9):checked
  ~ ul
  > li:nth-child(9)
  > label
  svg {
  fill: #ffffff;
}
@media (max-width: 600px) {
  .tabs
    input[name="tab-control"]:nth-of-type(9):checked
    ~ ul
    > li:nth-child(9)
    > label {
    background: rgba(0, 0, 0, 0.08);
  }
}

.tabs input[name="tab-control"]:nth-of-type(9):checked ~ .slider {
  transform: translateX(801%);
}
.tabs
  input[name="tab-control"]:nth-of-type(9):checked
  ~ .content
  > section:nth-child(9) {
  display: block;
}
/*-----*/
@keyframes content {
  from {
    opacity: 0;
    transform: translateY(5%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
}
@media (max-width: 1000px) {
  .tabs ul li label {
    white-space: initial;
  }
  .tabs ul li label br {
    display: initial;
  }
  .tabs ul li label svg {
    height: 1.5em;
  }
}
@media (max-width: 600px) {
  .tabs ul li label {
    padding: 5px;
    border-radius: 5px;
  }
  .tabs ul li label span {
    display: none;
  }
  .tabs .slider {
    display: none;
  }
  .tabs .content {
    margin-top: 20px;
  }
  .tabs .content section h2 {
    display: block;
  }
}

.side_menu {
  color: #000000;
}

.content_title {
  left: 447px;

  text-align: left;
  font: normal normal bold 20px/27px Recoleta;
  letter-spacing: 0px;
  color: #463196;
  opacity: 1;
}
.content_text {
  top: 541px;
  left: 447px;
  width: 829px;

  /* UI Properties */
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #0f0c1a;
  opacity: 1;
}
.menu_li {
  top: 422px;
  left: 602px;
  width: 61px;
  height: 21px;
  text-align: left;
  font: normal normal medium 15px/23px Poppins;
  font-weight: 300;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
