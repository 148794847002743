.side_exam a {
  top: 694px;

  width: 100%;

  font: normal normal medium 14px/21px Poppins;
  letter-spacing: 0px;
  color: #1ba643;
  text-transform: capitalize;
  opacity: 0.9;
  margin-left: 30;
  text-decoration: none;
}

.content_text2 {
  top: 541px;
  left: 447px;
  width: 829px;

  /* UI Properties */
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #0f0c1a;
  opacity: 1;
}

#summary {
  font-size: 14px;
  line-height: 1.5;
}

#summary p.collapse:not(.show) {
  height: 22px !important;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  margin: 0;

}

#summary p.collapsing {
  min-height: 42px !important;
}

#summary a.collapsed:after {
  content: 'Read More';
  color: #1BA643;

}

#summary a:not(.collapsed):after {
  content: 'Read Less';
  color: #1BA643;

}

@media only screen and (max-width: 600px) {
  .content_text2 {
    top: 541px;
    left: 447px;
    width: 100%;

    /* UI Properties */
    text-align: left;
    font: normal normal normal 16px/25px Poppins;
    letter-spacing: 0px;
    color: #0f0c1a;
    opacity: 1;
  }
}