.left_card_search {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 13px #4631961c;
  border: 1px solid #bebebe;
  border-radius: 5px;
  opacity: 1;
}
.tabs ul {
  list-style-type: none;
  padding-left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  border-radius: 5px;
  opacity: 1;
  color: white;
}
.menu_li2 {
  color: #0f0c1a;
}
.bachelor {
  top: 642px;
  left: 477px;
  width: 343px;
  height: 23px;
  text-align: left;
  font: normal normal 600 16px/25px Poppins;
  letter-spacing: 0px;
  color: #0f0c1a;
  opacity: 0.9;
}
.star {
  top: 646px;
  left: 921px;
  width: 11px;
  height: 11px;
  transform: matrix(-0.71, -0.71, 0.71, -0.71, 0, 0);
  background: #1ba643 0% 0% no-repeat padding-box;
  opacity: 1;
}
.eligibility_start {
  top: 730px;
  left: 500px;

  height: 20px;
  text-align: left;
  text-decoration: underline;
  font: normal normal 600 14px/21px Poppins;
  letter-spacing: 0px;
  color: #463196;
  opacity: 1;
  text-decoration: none;
}
.eligibility {
  top: 730px;
  left: 500px;

  height: 20px;
  text-align: left;
  text-decoration: underline;
  font: normal normal 600 14px/21px Poppins;
  letter-spacing: 0px;
  color: #463196;
  opacity: 1;
}
.eligibility_text {
  top: 731px;
  left: 575px;
  width: 75px;
  height: 19px;
  text-align: left;
  font: normal normal medium 13px/20px Poppins;
  letter-spacing: 0px;
  color: #0f0c1a;
  opacity: 0.9;
  margin-left: 10px;
}
.eligibility_text2 {
  top: 729px;
  left: 840px;

  height: 19px;
  text-align: left;
  font: normal normal medium 13px/20px Poppins;
  letter-spacing: 0px;
  color: #0f0c1a;
  opacity: 0.9;
  margin-left: 10px;
}

.right_side_nav .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: #0f0c1a;
}

.search_span {
  text-align: left;
  font: normal normal normal 10px/16px Poppins;
  letter-spacing: 0px;
  color: #0f0c1a;
  text-decoration: none;
}
