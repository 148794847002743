.mock_instruction_header_bg {
  height: 86px;
  background: #463196 0% 0% no-repeat padding-box;
}
.mock_instruction_header {
  font: normal normal 600 30px/46px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
}
.mock_instruction_text {
  top: 248px;
  left: 92px;

  height: 40px;
  text-align: left;
  font: normal normal 600 29px/43px Poppins;
  letter-spacing: 0px;
  color: #463196;
}
.mock_instruction_text_p {
  top: 309px;
  left: 91px;

  height: 25px;
  text-align: left;
  font: normal normal medium 18px/27px Poppins;
  letter-spacing: 0px;
  color: #0f0c1a;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
}
