.mocktest {
  font: normal normal medium 12px/17px Poppins;
  letter-spacing: 0px;
  color: #939198;
  text-align: left;
}
.mock_test_card {
  top: 209px;
  left: 442px;

  height: 138px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 13px #0000000f;
  border: 1px solid #c8c1df;
  border-radius: 5px;
  opacity: 1;
}
.corporate_a {
  text-align: left;
  text-decoration: underline;
  font: normal normal medium 12px/18px Poppins;
  letter-spacing: 0px;
  color: #1ba643;
}
@media only screen and (max-width: 600px) {
  .mock_test_card {
    top: 209px;
    left: 442px;

    height: 238px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 13px #0000000f;
    border: 1px solid #c8c1df;
    border-radius: 5px;
    opacity: 1;
  }
}
