.flip_card {
  margin: 10px 10px;
}

/* Flip Cards CSS */
.card-container {
  display: grid;
  perspective: 700px;
}

.card-flip {
  display: grid;
  grid-template: 1fr / 1fr;
  grid-template-areas: "frontAndBack";
  transform-style: preserve-3d;
  transition: all 0.7s ease;
}

.card-flip div {
  backface-visibility: hidden;
  transform-style: preserve-3d;
}

.front {
  grid-area: frontAndBack;
}

.back {
  grid-area: frontAndBack;
  transform: rotateY(-180deg);
}

input[type="checkbox"]:checked + .card-container .card-flip {
  transform: rotateY(180deg);
}
.flip_btn {
  border: none;
  background-color: #082f7c;
  padding: 0.2em 1em;
  border-radius: 1em;
  color: white;
}
.flip_text_h5 {
  color: #082f7c;
  font-weight: 600;
}
