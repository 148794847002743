.worksheet_text {
  text-decoration: none;
  font: normal normal medium 12px/17px Poppins;
  letter-spacing: 0px;
  color: #939198;
  margin: 0%;
  padding: 0%;
  margin-left: 20px;
  margin-top: -2px;
}
