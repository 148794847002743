.menu {
  /* UI Properties */
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 16px #0000001f;
}

.logo {
  top: 40px;
  left: 91px;
  width: 81px;
  height: 40px;
  /* UI Properties */
  text-align: left;
  font: normal normal medium 29px/43px Poppins;
  letter-spacing: 0px;
  color: #0f0c1a;
  opacity: 0.7;
}


.menu_img {
  top: 35px;
  left: 330px;
  width: 28px;
  height: 28px;
  /* UI Properties */

  opacity: 1;
}

.indicator li {
  position: relative;
  transition: none;
  transform: scale(1, 1);
  width: auto;
}

.indicator ::before {
  content: '';
  display: block;
  height: 5px;
  background: #463196;
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  border-radius: 10px;
  transform: scale(0, 1);
  transition: transform ease-in-out 250ms;
}

.indicator a:hover::before {
  transform: scale(1, 1);
}


.menu_li {
  text-decoration: none;
}

.search_img {
  margin-right: 35px;
}

.modal_header {
  text-align: left;
  font: normal normal bold 16px/22px Recoleta;
  letter-spacing: 0px;
  color: #463196;
  text-transform: uppercase;
}

.text2 {
  text-align: right;

  font: normal normal medium 10px/16px Poppins;
  letter-spacing: 0px;
  color: #7a7880;
  font-size: 12px;
  text-decoration: none;
}

.text2 {
  float: left;

  font: normal normal medium 10px/16px Poppins;
  letter-spacing: 0px;
  color: #7a7880;
  font-size: 12px;
}

.search_menu_modal .modal-content {
  margin-top: 95px;
}

.logoSearch_navbar {
  display: flex;
  gap: 0.75rem;
}

.search_navbar {
  display: flex;
  justify-content: space-between;
  background-color: white;
  border-radius: 10px;
  padding: 5px 18px;
  width: 100%;
}

.search_navbar>input {
  border: none;
  outline: none;
  background-color: transparent;
  padding: 30px;
}

.s-icon_navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 5px;

  /* background: linear-gradient(98.63deg, #8f00ff 0%, #ff18b7 100%); */
}

.s-icon_navbar:hover {
  cursor: pointer;
}

#offcanvasNavbar {
  display: none;
}

.menu_option2 {
  display: none
}

.main_sm_menu {
  display: none;
}

.dropdown-menu a {
  height: 24px
}

.dropdown-menu a:hover {
  background-color: #EEEEEE;

  border-radius: 5%;
  width: 160px;
  height: 24px;

}

.navbar_main {
  margin: 0px auto;
}

@media only screen and (max-width: 600px) {
  .navbar_main .nav-link {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }

  .navbar_main {}

  .search5 {
    display: flex;
    width: 191px;
    border: 1px solid #a2a2a2;
    border-radius: 9px;
    padding: 0 7px;

  }


  .search5>input {
    border: none;
    width: 173px;
  }

  .s-icon2 {
    margin-left: -30px;
  }

  .main_sm_menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .dropdown {
    width: 400px;
  }

  .menu_img_sm_text {
    width: 180px;
  }

  .menu_option {
    display: none;
  }

  .menu_option2 {
    display: inline
  }

  #offcanvasNavbar {
    display: inline;
  }

  .nav_all {
    display: flex;
  }

  .dropdown_menu {
    color: #0F0C1A;
    text-transform: initial;
  }

  .dropdown-toggle::after {
    float: inline-end;
    margin-top: 13px;
  }

  .dropdown-item {
    padding: 0;
    font-size: 12px;
    margin-bottom: 10px;
    text-transform: initial;
  }

  .dropdown_text {
    color: #6A6A6A;
  }

  .menu_img_sm {
    margin-top: 12px;
    margin-right: 10px;
    width: 18px;
    height: 18px;
  }


  .get_app {
    z-index: -99999;

  }
}

@media (min-width: 600px) and (max-width: 992px) {
  .navbar_main .nav-link {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }



  .navbar_main ul {
    margin-left: 20px;
  }
}

@media (min-width: 992px) {
  .navbar_modal .modal-content {
    margin-left: 300px;
    margin-top: 95px;
  }
}