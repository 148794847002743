nav ul li a {
  top: 569px;
  left: 144px;
  width: 81px;
  height: 20px;
  text-align: left;
  font: normal normal medium 14px/21px Poppins;
  letter-spacing: 0px;
  color: #0f0c1a;
  text-transform: uppercase;
  opacity: 0.9;
  text-decoration: none;
}

.side li {
  display: table;
  width: 100%;
}

.side ul li {
  padding: 10px;
}

.side ul li:hover {
  top: 512px;
  left: 91px;

  height: 40px;
  background: #a66cff 0% 0% no-repeat padding-box;
  border-left: 5px solid #463196;
}

.side ul li:hover a {
  top: 569px;

  height: 20px;
  text-align: left;
  font: normal normal medium 14px/21px Poppins;
  letter-spacing: 0px;
  color: #0f0c1a;
  text-transform: uppercase;
  opacity: 0.9;
  text-decoration: none;
}

.side ul li a.current {
  background: black;
}

#myScrollspy {
  position: sticky;
  top: 20px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 13px #0000000f;
  border-radius: 5px;
  opacity: 1;
  padding: 20px;
}

.rightSide {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 13px #0000000f;
}

@media only screen and (max-width: 600px) {
  .rightSide {
    background: #ffffff;
    box-shadow: none;

  }
}

@media (min-width: 768px) and (max-width: 991.98px) {}