.corporate_header {
  margin-top: 80px;
  left: 0px;

  height: 20px;
  text-align: left;
  font: normal normal medium 14px/21px Poppins;
  letter-spacing: 0px;
  color: #463196;
}
.corporate_header_other {
  top: 0px;
  left: 113px;

  height: 20px;
  text-align: left;
  font: normal normal medium 14px/21px Poppins;
  letter-spacing: 0px;
  color: #939198;
}

.corporate_card {
  height: 81px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 13px #0000000f;
  border: 1px solid #c8c1df;
  border-radius: 5px;
}
.corporate_h4 {
  text-align: left;
  font: normal normal 600 15px/17px Poppins;
  letter-spacing: 0px;
  text-transform: capitalize;
  color: #463196;
}

.corporate_span_like {
  font: normal normal medium 12px/18px Poppins;
  letter-spacing: 0px;
  color: #939198;
  text-decoration: none;
}
.corporate_btn {
  width: 133px;
  height: 42px;
  background: #cecece 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
}
.corporate_btn2 {
  width: 133px;
  height: 42px;
  background: #c8c1df 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
}
.corporate_btn_icon {
  font: normal normal medium 11px/17px Poppins;
  letter-spacing: 0px;
  color: #0f0c1a;
  margin-left: 10px;
}
.corporate_pdf {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  box-shadow: 0px 6px 13px #0000000f;
  border: 1px solid #c8c1df;
  border-radius: 5px;
}
.corporate_pdf_text {
  text-align: left;
  font: normal normal 600 15px/17px Poppins;
  letter-spacing: 0px;
  text-transform: capitalize;
  color: #463196;
  height: 21px;
  margin-top: 80px;
}

@media only screen and (max-width: 600px) {
  .corporate_card {
    height: 181px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 13px #0000000f;
    border: 1px solid #c8c1df;
    border-radius: 5px;
    margin-bottom: 10px;
  }
}
