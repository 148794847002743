.login_h1 {
  font: normal normal 600 57px/86px Poppins;
  letter-spacing: 0px;
  color: #0f0c1a;
  opacity: 1;
}

.login_h6 {
  font: normal normal normal 22px/33px Poppins;
  letter-spacing: 0px;
  color: #0f0c1a;
}

.img_card2 {
  margin-left: -600px;
  width: 474px;
  height: 565px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 7px 36px #0000001f;
}

.login_p {
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  text-decoration: none;
}

.login_p1 {
  text-align: right;
  text-decoration: underline;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #939198;
}

.login_left {
  height: 100vh;
  background: #463196 0% 0% no-repeat padding-box;
}

@media only screen and (max-width: 600px) {
  .img_card2 {
    margin-left: 0%;
    width: 100%;
    height: 565px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 7px 36px #0000001f;
  }
}