.left_bg {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #0000001a;
  height: 700px;
}
.mock_exam_h3 {
  text-align: left;
  font: normal normal 600 20px/17px Poppins;
  letter-spacing: 0px;
  color: #463196;
}
.mock_exam_p {
  text-align: left;
  font: normal normal normal 16px/17px Poppins;
  letter-spacing: 0px;
  color: #939198;
}
.mock_exam_hr {
  border: 1px solid #c8c1df;
}
.mock_exam_h4 {
  text-align: left;
  font: normal normal medium 16px/17px Poppins;
  letter-spacing: 0px;
  color: #0f0c1a;
}
.mock_exam_img {
  top: 322px;
  padding: 0%;
  margin: 0%;
  margin-left: -20px;
  height: 321px;
}
.mock_exam_div {
  width: 300px;
}
.mock_exam > label {
  text-align: left;
  font: normal normal normal 16px/17px Poppins;
  letter-spacing: 0px;
  color: #939198;
}
.mock_exam_btn {
  top: 676px;
  left: 91px;
  width: 106px;
  height: 48px;
  background: #eeeeee 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
}
.mock_exam_btn2 {
  top: 676px;
  left: 91px;
  width: 136px;
  height: 48px;
  background: #eeeeee 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
}
.mock_exam_btn3 {
  top: 676px;
  left: 91px;
  width: 136px;
  height: 48px;
  background: #c8c1df 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
}
.mock_exam_btn4 {
  width: 136px;
  height: 48px;
  background: #c8c1df 0% 0% no-repeat padding-box;
  border-radius: 5px;
  border: none;
  margin-left: 60px;
}
.mock_exam_card {
  top: 196px;
  left: 959px;
  width: 312px;
  height: 97px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #0000001a;
  border-radius: 5px;
}
.mock_exam_a1 {
  font: normal normal medium 16px/17px Poppins;
  letter-spacing: 0px;
  color: #0f0c1a;
}
.mock_exam_a2 {
  text-decoration: none;
  font: normal normal medium 13px/20px Poppins;
  letter-spacing: 0px;
  color: #0f0c1a;
}
.question_task {
  top: 369px;
  left: 988px;
  width: 254px;
  height: 341px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #0000001a;
  border-radius: 5px;
}
.question_h6 {
  height: 23px;
  padding-top: 10px;
  font: normal normal 600 16px/17px Poppins;
  letter-spacing: 0px;
  color: #463196;
}
.question_h1_box {
  margin-top: -52px;
  color: white;
}

@media only screen and (max-width: 600px) {
  .mock_exam_btn2 {
    top: 676px;
    left: 91px;
    width: 136px;
    height: 48px;
    background: #eeeeee 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: none;
    margin: 10px 0px;
  }
}
